import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import styles from '../../index.module.scss'
const chromeImg = require('@/assets/images/room/chrome.png')

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return () => (
      <div class={styles.support}>
        <div class={styles.title}>{t('room.support.title')}</div>
        <div class={styles.desc}>{t('room.support.desc')}</div>
        <a href="https://www.google.com/intl/en_us/chrome">
          <img src={chromeImg} />
        </a>
      </div>
    )
  }
})
