import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import styles from '../../index.module.scss'
const failedImg = require('@/assets/images/room/failed.png')

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return () => (
      <div class={styles.failed}>
        <div class={styles.title}>{t('room.failed.title')}</div>
        <img src={failedImg} />
        <div class={styles.desc}>{t('room.failed.desc')}</div>
      </div>
    )
  }
})
