import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import AgoraRTC from 'agora-rtc-sdk-ng'
import styles from '../index.module.scss'
import Tips from './status/tips'
import Auth from './content/auth'
import AuthError from './status/auth-error'
import Failed from './status/failed'
import Preview from './content/preview'
import serve from '@/serve'

const AUTH_ACTION_DEFAULT_ON = '1'

const isAuthActionDefault =
  localStorage.getItem('isAuthActionDefault') === AUTH_ACTION_DEFAULT_ON

export default defineComponent({
  setup(_props, context) {
    const route = context.root.$route

    const loading = ref(true)

    const isAuth = ref(false)
    const isAuthAction = ref(!isAuthActionDefault)
    const isJoin = ref(false)
    const notJoin = ref(false)

    const getToken = async () => {
      const { id } = route.params
      loading.value = true
      const data = await serve['invite-guests'].getInviteGuestsToken({
        data: { activityId: id }
      })

      if (data === serve.FAIL) {
        return serve.FAIL
      }
      loading.value = false
      if (!data) {
        notJoin.value = true
        return
      }

      isJoin.value = true
      return data
    }

    const allow = () => {
      AgoraRTC.getDevices()
        .then((devices) => {
          isAuth.value = true
        })
        .finally(() => {
          localStorage.setItem('isAuthActionDefault', AUTH_ACTION_DEFAULT_ON)
          isAuthAction.value = false
        })
    }

    const openInteraction = async () => {
      await serve['invite-guests'].openInteraction({
        data: {
          activityId: route.params.id,
          uid: 1,
          muteAudio: 1
        }
      })
    }
    const closeInteraction = async () => {
      await serve['invite-guests'].closeInteraction({
        data: {
          activityId: route.params.id
        }
      })
    }

    onMounted(async () => {
      await getToken()

      if (isAuthAction.value) {
        return
      }
      await allow()
    })

    const render = () => {
      if (loading.value) {
        return <Tips />
      }

      if (notJoin.value) {
        return <Failed />
      }

      if (isAuthAction.value) {
        return <Auth onAllow={allow} />
      }

      if (isJoin.value) {
        if (!isAuth.value) {
          return <AuthError />
        }

        return (
          <Preview
            onJoinFail={() => {
              notJoin.value = true
            }}
          />
        )
      }
    }

    return () => {
      return (
        <div class={styles.content}>
          {/* <div onClick={openInteraction}>开启</div>
          <div onClick={closeInteraction}>关闭</div> */}
          {render()}
        </div>
      )
    }
  }
})
