import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import styles from '../../index.module.scss'
const safariImg = require('@/assets/images/room/safari.png')

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return () => (
      <div class={styles.isoSafari}>
        <div class={styles.title}>{t('room.isoSafari.title')}</div>
        <div class={styles.desc}>
          {t('room.isoSafari.desc[0]')}
          <br />
          {t('room.isoSafari.desc[1]')}
          <img src={safariImg} />
          {t('room.isoSafari.desc[2]')}
        </div>
        <div class={styles.title}>{t('room.isoSafari.question')}</div>
        <div
          class={styles.desc}
          style={{ textAlign: 'left', padding: '0 80px' }}
        >
          {t('room.isoSafari.answer[0]')}
          <br />
          {t('room.isoSafari.answer[1]')}
          <span class={styles.copy}> {t('room.isoSafari.copy')}</span> <br />
          {t('room.isoSafari.answer[2]')}
          <br />
          {t('room.isoSafari.answer[3]')}
        </div>
        <div class={styles.title}>{t('room.isoSafari.question2')}</div>
        <div class={styles.desc}>{t('room.isoSafari.answer2')}</div>
      </div>
    )
  }
})
