import { defineComponent, ref } from '@vue/composition-api'
import Header from './components/header'
import Content from './components/content'
import NotSupport from './components/status/not-support'
import styles from './index.module.scss'
import { browser, os } from '@yunxi/helper'
import IsoNotSafari from './components/status/iso-not-safari'

// import VConsole from 'vconsole'

// const vConsole = new VConsole()

export default defineComponent({
  setup() {
    return () => (
      <div class={styles.room}>
        <Header />
        <div class={styles.maxWidth}>
          {(() => {
            if (os.isPc) {
              if (!os.isChrome) {
                return <NotSupport />
              }
            }
            if (os.isIOS) {
              if (browser !== 'safari') {
                return <IsoNotSafari />
              }
            }

            return <Content />
          })()}
        </div>
      </div>
    )
  }
})
