<template>
  <span class="loading-icon">
    <i class="loading-icon-line"></i>
  </span>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style lang="scss" scoped>
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.loading-icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #333333;
  border-radius: 16px;
}

.loading-icon-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 3px;
  margin: -2px 0 0 -12px;
  background: #fff;
  transform-origin: center;
  animation: shake 2s 0.1s linear infinite;
}
</style>
