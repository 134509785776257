import { defineComponent } from '@vue/composition-api'
import styles from '../../index.module.scss'
const morePeopleImg = require('@/assets/images/room/more-people.png')
import { useI18n } from 'vue-i18n-composable'

import LoadingIcon from '../icon/loading-icon.vue'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    // console.log(t)
    return () => (
      <div class={styles.tips}>
        <div class={styles.title}>{t('room.tips.title')}</div>
        <img src={morePeopleImg} />
        <div class={styles.tip}> {t('room.tips.tip')} </div>
        <div class={styles.desc}>
          {t('room.tips.desc[0]')}
          <br />
          {t('room.tips.desc[1]')}
          <br />
          {t('room.tips.desc[2]')}
        </div>
        <div class={styles.loading}>
          <span class={styles.loadingIcon}>
            <LoadingIcon />
          </span>
          <span>Loading...</span>
        </div>
      </div>
    )
  }
})
