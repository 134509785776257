import {
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack
} from 'agora-rtc-sdk-ng'
import { useI18n } from 'vue-i18n-composable'
import styles from '../../index.module.scss'

const CameraTabImg = require('@/assets/images/room/camera-tab.png')
const AudioTabImg = require('@/assets/images/room/audio-tab.png')
const settingsMicImg = require('@/assets/images/room/settings-mic.png')
const settingsSpeakerImg = require('@/assets/images/room/settings-speaker.png')

const enum SettingsTab {
  Camera = 'Camera',
  Audio = 'Audio'
}

const settingVolLength = 5

const definitionOptions = [
  {
    label: '480p',
    value: 480
  },
  {
    label: '720p',
    value: 720
  },
  {
    label: '1080p',
    value: 1080
  }
]

export default defineComponent({
  props: {
    visible: Boolean,
    value: {
      type: Object as PropType<{
        cameraId: string
        microphoneId: string
        speakerId: string
        definition: number
        micName: string
        rate: number
      }>,
      required: true
    },
    microphoneId: String,
    speakerId: String,
    definition: Number,
    micName: String,
    rate: Number,
    onConfirm: Function,
    onClose: Function,
    deviceList: {
      type: Object as PropType<{
        videoDevices: MediaDeviceInfo[]
        micDevices: MediaDeviceInfo[]
        speakerDevices: MediaDeviceInfo[]
      }>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()

    const showSetting = ref(false)
    console.log(props)

    const videoTrack = ref<ICameraVideoTrack>()
    const micTrack = ref<IMicrophoneAudioTrack>()
    const audioFileTrack = ref<HTMLAudioElement>()

    const deviceInfo = reactive({
      cameraId: '',
      microphoneId: '',
      speakerId: '',
      definition: 0,
      micName: '',
      rate: 0
    })

    const localVolumeLevel = ref(0)

    const currentTab = ref(SettingsTab.Camera)

    const isPlaying = ref(false)

    watch(
      () => props.visible,
      (value) => {
        showSetting.value = value
        if (value) {
          deviceInfo.cameraId = props.value.cameraId
          deviceInfo.microphoneId = props.value.microphoneId
          deviceInfo.speakerId = props.value.speakerId
          deviceInfo.definition = props.value.definition
          deviceInfo.micName = props.value.micName
          deviceInfo.rate = props.value.rate
          getDevices()
          return
        }
      },
      {
        immediate: true
      }
    )

    watch(
      () => props.deviceList.videoDevices,
      (devices, oldDevices) => {
        if (devices.length < (oldDevices?.length ?? 0)) {
          const selectDevice = devices.find(
            (device) => device.deviceId === deviceInfo.cameraId
          )
          if (selectDevice) {
            return
          }

          if (devices[0]) {
            deviceInfo.cameraId = devices[0].deviceId
            handleChangeCamera(devices[0].deviceId)
            return
          }
          deviceInfo.cameraId = ''
        }
      },
      {
        deep: true
      }
    )
    watch(
      () => props.deviceList.micDevices,
      (devices, oldDevices) => {
        if (devices.length < (oldDevices?.length ?? 0)) {
          const selectDevice = devices.find(
            (device) => device.deviceId === deviceInfo.microphoneId
          )
          if (selectDevice) {
            return
          }

          if (devices[0]) {
            deviceInfo.microphoneId = devices[0].deviceId
            handleChangeMic(devices[0].deviceId)
            return
          }
          deviceInfo.microphoneId = ''
        }
      },
      {
        deep: true
      }
    )
    watch(
      () => props.deviceList.speakerDevices,
      (devices, oldDevices) => {
        if (devices.length < (oldDevices?.length ?? 0)) {
          const selectDevice = devices.find(
            (device) => device.deviceId === deviceInfo.speakerId
          )
          if (selectDevice) {
            return
          }

          if (devices[0]) {
            deviceInfo.speakerId = devices[0].deviceId
            return
          }
          deviceInfo.speakerId = ''
        }
      },
      {
        deep: true
      }
    )

    const switchTab = (val: SettingsTab) => {
      currentTab.value = val
    }

    const handleConfirm = () => {
      handleClose()
      emit('confirm', deviceInfo)
    }

    const handleClose = () => {
      showSetting.value = false
      emit('close')
      videoTrack.value?.close()
      micTrack.value?.close()
      // localVideoTrack.value?.play('testCamera')
    }

    const getDevices = () => {
      AgoraRTC.createCameraVideoTrack({
        cameraId: deviceInfo.cameraId
      }).then((track) => {
        videoTrack.value = track
        track.play('settingCamera', {
          fit: 'contain'
        })
      })

      AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: deviceInfo.microphoneId
      }).then((track) => {
        micTrack.value = track
        setInterval(() => {
          const level = track.getVolumeLevel()
          // console.log('local stream audio level', level)
          localVolumeLevel.value = level
        }, 500)
      })
    }

    const handleChangeCamera = (deviceId: string) => {
      videoTrack.value?.setMuted(false)
      videoTrack.value?.setDevice(deviceId).catch(() => {
        videoTrack.value?.setMuted(true)
      })

      const frameData = videoTrack.value?.getCurrentFrameData() || {
        width: 0,
        height: 0
      }
      deviceInfo.rate = +(frameData?.width / frameData?.height).toFixed(3)
      handleChangeDefinition(+deviceInfo.definition)
    }
    const handleChangeDefinition = (value: number) => {
      videoTrack.value?.setEncoderConfiguration({
        height: value,
        width: value * deviceInfo.rate
      })
    }
    const handleChangeMic = (deviceId: string) => {
      const device = props.deviceList.micDevices.find(
        (device) => device.deviceId === deviceId
      )
      deviceInfo.micName = device?.label || ''
      micTrack.value?.setDevice(deviceId)
    }

    const handleTestSpeaker = async (play: boolean) => {
      isPlaying.value = play
      if (!play) {
        audioFileTrack.value?.pause()
        return
      }

      if (!audioFileTrack.value) {
        return
      }

      audioFileTrack.value.setSinkId(deviceInfo.speakerId)
      audioFileTrack.value.play()
    }

    onMounted(async () => {
      audioFileTrack.value = document.createElement('audio')
      audioFileTrack.value.src =
        "https://files.musopen.org/recordings/e73bfc95-e0a9-4476-83dd-eea41a30ec57.mp3?filename=Symphonie+Fantastique,+Op.+14+-+5+Dreams+of+a+Witches'+Sabbath.mp3"
      audioFileTrack.value.loop = true
    })

    return () => {
      const settingList = [
        {
          label: t(`room.setting.Camera`),
          icon: CameraTabImg,
          value: SettingsTab.Camera
        },
        {
          label: t(`room.setting.Audio`),
          icon: AudioTabImg,
          value: SettingsTab.Audio
        }
      ]

      const { deviceList } = props
      const { muted } = videoTrack.value || {}
      // console.log(muted)
      return (
        <el-dialog
          custom-class="room-setting"
          append-to-body
          title="Settings"
          visible={showSetting.value}
          width="608px"
          onClose={handleClose}
        >
          <div class={styles.setting}>
            <div class={styles.tabs}>
              {settingList.map((tab) => (
                <div
                  onClick={() => switchTab(tab.value)}
                  class={[
                    styles.tabItem,
                    tab.value === currentTab.value ? styles.tabActive : ''
                  ]}
                >
                  <img src={tab.icon} />
                  {tab.label}
                </div>
              ))}
            </div>
            <div class={styles.settingBox}>
              <div
                style={{
                  display:
                    currentTab.value === SettingsTab.Camera ? 'block' : 'none'
                }}
              >
                <div id="settingCamera" class={styles.settingVideo}></div>
                <div class={styles.settingLabel}>
                  {t('room.setting.Camera')}
                </div>
                <div class={styles.settingSelect}>
                  <el-select
                    size="small"
                    vModel={deviceInfo.cameraId}
                    onChange={handleChangeCamera}
                  >
                    {deviceList.videoDevices.map((device) => (
                      <el-option
                        label={device.label}
                        value={device.deviceId}
                      ></el-option>
                    ))}
                  </el-select>
                </div>
                <div class={styles.settingLabel}>
                  {t('room.setting.resolution')}
                </div>
                <div class={styles.settingSelect}>
                  <el-select
                    size="small"
                    vModel={deviceInfo.definition}
                    onChange={handleChangeDefinition}
                  >
                    {definitionOptions.map((item) => (
                      <el-option
                        label={item.label}
                        value={item.value}
                      ></el-option>
                    ))}
                  </el-select>
                </div>
              </div>
              <div
                style={{
                  display:
                    currentTab.value === SettingsTab.Audio ? 'block' : 'none'
                }}
              >
                <div class={styles.settingLabel}>{t('room.setting.Mic')}</div>
                <div class={styles.settingSelect}>
                  <el-select
                    size="small"
                    vModel={deviceInfo.microphoneId}
                    onChange={handleChangeMic}
                  >
                    {deviceList.micDevices.map((device) => (
                      <el-option
                        label={device.label}
                        value={device.deviceId}
                      ></el-option>
                    ))}
                  </el-select>
                  <img src={settingsMicImg} />
                  <div class={styles.settingVolume}>
                    {Array(settingVolLength)
                      .fill(1)
                      .map((_item, i) => {
                        const volIndex = Math.ceil(
                          localVolumeLevel.value * settingVolLength
                        )
                        if (i < volIndex) {
                          return (
                            <div class={styles.settingVolumeItemActive}></div>
                          )
                        }
                        return <div class={styles.settingVolumeItem}></div>
                      })}
                  </div>
                </div>
                <div class={styles.settingLabel}>
                  {t('room.setting.Speaker')}
                </div>
                <div class={styles.settingSelect}>
                  <el-select size="small" vModel={deviceInfo.speakerId}>
                    {deviceList.speakerDevices.map((device) => (
                      <el-option
                        label={device.label}
                        value={device.deviceId}
                      ></el-option>
                    ))}
                  </el-select>
                  <img src={settingsSpeakerImg} />

                  <span
                    class={styles.testBtn}
                    onClick={() => handleTestSpeaker(!isPlaying.value)}
                  >
                    {isPlaying.value ? 'Playing' : 'Test'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <span slot="footer">
            <el-button type="primary" disabled={muted} onClick={handleConfirm}>
              {t('room.setting.Confirm')}
            </el-button>
            <el-button onClick={handleClose}>
              {t('room.setting.Cancel')}
            </el-button>
          </span>
        </el-dialog>
      )
    }
  }
})
