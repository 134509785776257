import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import styles from '../../index.module.scss'
const authManualImg = require('@/assets/images/room/auth-manual.png')

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return () => (
      <div class={styles.authError}>
        <div class={styles.title}>{t('room.authError.title')}</div>
        <img src={authManualImg} />
        <div class={styles.desc}>
          {t('room.authError.desc[0]')}
          <br />
          {t('room.authError.desc[1][0]')}
          <span> {t('room.authError.desc[1][1]')}</span>
          {t('room.authError.desc[1][2]')}{' '}
          <span> {t('room.authError.desc[1][3]')}</span>{' '}
          {t('room.authError.desc[1][4]')} <br />
          {t('room.authError.desc[2]')}
        </div>
      </div>
    )
  }
})
