import serve from '@/serve'
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref
} from '@vue/composition-api'
import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  RemoteStreamType
} from 'agora-rtc-sdk-ng'
import { Message } from 'element-ui'
import { useI18n } from 'vue-i18n-composable'

import styles from '../../index.module.scss'
import Camera from './camera'
import SettingDialog from './setting-dialog'

const micImg = require('@/assets/images/room/mic.png')

const muteImg = require('@/assets/images/room/mute.png')
const unmuteImg = require('@/assets/images/room/unmute.png')

const camImg = require('@/assets/images/room/cam.png')
const noCamImg = require('@/assets/images/room/no-cam.png')

const settingsImg = require('@/assets/images/room/settings.png')
const leaveImg = require('@/assets/images/room/leave.png')

const nameImg = require('@/assets/images/room/name.png')

const ControlItem = defineComponent({
  props: {
    label: String,
    icon: String,
    onClick: Function
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click')
    }
    return () => (
      <div class={styles.controlItem} onClick={handleClick}>
        <img src={props.icon} />
        <div>{props.label}</div>
      </div>
    )
  }
})

export default defineComponent({
  props: ['onJoinFail'],
  setup(_props, { root, emit }) {
    const route = root.$route
    const { t } = useI18n()

    const deviceList = reactive<{
      videoDevices: MediaDeviceInfo[]
      micDevices: MediaDeviceInfo[]
      speakerDevices: MediaDeviceInfo[]
    }>({
      videoDevices: [],
      micDevices: [],
      speakerDevices: []
    })

    const localVolumeLevel = ref(0)

    const localVideoTrack = ref<ICameraVideoTrack>()
    const localAudioTrack = ref<IMicrophoneAudioTrack>()

    const audioTrackList = ref<IRemoteAudioTrack[]>([])

    const client = ref<IAgoraRTCClient>()

    const deviceInfo = reactive({
      cameraId: '',
      microphoneId: '',
      speakerId: '',
      definition: 1080,
      micName: 'Mic',
      rate: 16 / 9
    })

    const showSetting = ref(false)

    const isJoined = ref(false)
    const joinLoading = ref(false)
    const name = ref('')

    const host = ref()

    const getDevices = () => {
      AgoraRTC.getDevices().then((devices) => {
        console.log(devices)
        deviceList.micDevices = devices.filter(function (device) {
          return device.kind === 'audioinput'
        })

        deviceList.videoDevices = devices.filter(function (device) {
          return device.kind === 'videoinput'
        })

        deviceList.speakerDevices = devices.filter(function (device) {
          return device.kind === 'audiooutput'
        })
        let defaultDevice
        try {
          defaultDevice = JSON.parse(localStorage.getItem('deviceInfo') || '{}')
        } catch {
          defaultDevice = {}
        }

        const defaultMicDevice =
          deviceList.micDevices.find(
            (device) => device.deviceId === defaultDevice.microphoneId
          ) ||
          deviceList.micDevices[0] ||
          {}

        deviceInfo.microphoneId = defaultMicDevice.deviceId
        deviceInfo.micName = defaultMicDevice.label

        deviceInfo.cameraId =
          deviceList.videoDevices.find(
            (device) => device.deviceId === defaultDevice.cameraId
          )?.deviceId || deviceList.videoDevices[0]?.deviceId

        deviceInfo.speakerId =
          deviceList.speakerDevices.find(
            (device) => device.deviceId === defaultDevice.speakerId
          )?.deviceId || deviceList.speakerDevices[0]?.deviceId

        return Promise.all([
          AgoraRTC.createCameraVideoTrack({
            cameraId: deviceInfo.cameraId,
            encoderConfig: {
              height: deviceInfo.definition,
              width: deviceInfo.definition * deviceInfo.rate
            },
            optimizationMode: 'detail'
          }),
          AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: deviceInfo.microphoneId
          })
        ]).then((res) => {
          const [videoTrack, audioTrack] = res
          localVideoTrack.value = videoTrack
          localAudioTrack.value = audioTrack
          console.log('localVideoTrack', localVideoTrack.value)
          videoTrack?.play('meVideo', {
            fit: 'contain'
          })
          setInterval(() => {
            const level = audioTrack.getVolumeLevel()
            console.log('local stream audio level', level)
            localVolumeLevel.value = level
          }, 500)
        })
      })
    }

    const switchMute = () => {
      localAudioTrack.value?.setMuted(!localAudioTrack.value.muted)
    }

    const switchCamera = () => {
      localVideoTrack.value?.setMuted(!localVideoTrack.value!.muted)
    }

    const openSetting = async () => {
      showSetting.value = true
    }

    const handleConfirm = (device) => {
      console.log('device', device)
      deviceInfo.cameraId = device.cameraId
      deviceInfo.microphoneId = device.microphoneId
      deviceInfo.speakerId = device.speakerId
      deviceInfo.definition = device.definition
      deviceInfo.rate = device.rate

      deviceInfo.micName = device.micName

      localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo))

      localAudioTrack.value?.setDevice(device.microphoneId)
      localVideoTrack.value?.setDevice(device.cameraId)
      localVideoTrack.value?.setEncoderConfiguration({
        height: device.definition,
        width: device.definition * device.rate
      })

      switchSpeaker(device.speakerId)
    }

    const joinRoom = async () => {
      const { id } = route.params
      joinLoading.value = true
      const data = await serve['invite-guests'].getInviteGuestsToken({
        data: { activityId: id, nick: name.value }
      })
      joinLoading.value = false
      if (data === serve.FAIL) {
        return
      }

      if (!data) {
        emit('joinFail')
        return
      }
      joinLoading.value = true

      await client
        .value!.join(process.env.VUE_APP_RTC_APPID, id, data.token, data.uid)
        .catch((err) => {
          console.log(err)
          Message.error(t('room.preview.joinFail') as string)
          throw err
        })
        .finally(() => {
          joinLoading.value = false
        })

      isJoined.value = true
      await nextTick()
      localVideoTrack.value?.play('meVideo', {
        fit: 'contain'
      })
      console.log(localAudioTrack.value, 'localAudioTrack')
      await client.value!.publish([
        localAudioTrack.value!,
        localVideoTrack.value!
      ])
    }

    const leaveRoom = async () => {
      client.value!.leave()
      window.location.href = 'https://www.yololiv.com/'
      isJoined.value = false
    }

    // const handleMute = () => {
    //   const { id } = route.params
    //   const data = serve['invite-guests'].muteAudio({
    //     data: {
    //       activityId: id,
    //       muteAudio: host.value?._trust_audio_mute_state_ ? 0 : 1,
    //       uid: host.value.uid
    //     }
    //   })
    // }

    const switchSpeaker = (speakerId) => {
      audioTrackList.value.forEach((track) => {
        track.setPlaybackDevice(speakerId)
      })
    }

    onMounted(() => {
      client.value = AgoraRTC.createClient({
        codec: 'vp8',
        mode: 'live',
        role: 'host'
      })
      // client.value.setLowStreamParameter({
      //   width: 160,
      //   height: 120,
      //   framerate: 15,
      //   bitrate: 120,
      // });

      // 开启双流模式。
      client.value
        .enableDualStream()
        .then(() => {
          console.log('enable dual stream success')
        })
        .catch((err) => {
          console.log(err)
        })

      client.value.on('exception', function (evt) {
        console.log(evt.code, evt.msg, evt.uid)
      })

      AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
        const devices = await AgoraRTC.getMicrophones()
        deviceList.micDevices = devices
        console.log(devices, 'mic')
        const selectDevice = devices.find(
          (device) => device.deviceId === deviceInfo.microphoneId
        )
        deviceInfo.micName = devices[0].label || 'default Mic'

        if (changedDevice.state === 'ACTIVE') {
          return
        }

        // 拔出设备为当前设备时，切换到一个已有的设备。
        if (changedDevice.state === 'INACTIVE') {
          if (selectDevice) {
            return
          }

          if (devices[0]) {
            localAudioTrack.value?.setDevice(devices[0].deviceId)
            deviceInfo.microphoneId = devices[0].deviceId
            return
          }
          deviceInfo.micName = 'Without a Mic'
          deviceInfo.microphoneId = ''
        }
      }

      AgoraRTC.onCameraChanged = async (changedDevice) => {
        const devices = await AgoraRTC.getCameras()
        deviceList.videoDevices = devices
        console.log(devices, 'camera')
        if (changedDevice.state === 'ACTIVE') {
          return
        }
        // 拔出设备为当前设备时，切换到一个已有的设备。
        if (changedDevice.state === 'INACTIVE') {
          const selectDevice = devices.find(
            (device) => device.deviceId === deviceInfo.cameraId
          )

          if (selectDevice) {
            return
          }

          if (devices[0]) {
            localVideoTrack.value?.setDevice(devices[0].deviceId)
            deviceInfo.cameraId = devices[0].deviceId
            return
          }
          deviceInfo.cameraId = ''
        }
      }

      AgoraRTC.onPlaybackDeviceChanged = async (changedDevice) => {
        const devices = await AgoraRTC.getPlaybackDevices()
        console.log(devices, 'devices')
        deviceList.speakerDevices = devices

        if (changedDevice.state === 'ACTIVE') {
          return
        }
        // 拔出设备为当前设备时，切换到一个已有的设备。
        if (changedDevice.state === 'INACTIVE') {
          const selectDevice = devices.find(
            (device) => device.deviceId === deviceInfo.cameraId
          )

          if (selectDevice) {
            return
          }
          if (devices[0]) {
            deviceInfo.speakerId = devices[0].deviceId
            switchSpeaker(devices[0].deviceId)
            return
          }
          deviceInfo.speakerId = ''
        }
      }

      client.value.on('user-published', async (user, mediaType) => {
        // 发起订阅
        host.value = user

        const res = await client.value!.subscribe(user, mediaType)
        console.log(user, 'user')
        console.log(res)
        // 如果订阅的是音频轨道
        if (mediaType === 'audio') {
          const audioTrack = user.audioTrack
          // 自动播放音频
          audioTrack?.play()
          audioTrack?.setVolume(200)

          audioTrack?.setPlaybackDevice(deviceInfo.speakerId)
          console.log(audioTrack)
          // audioTrack?
        }

        if (mediaType === 'video') {
          if (user.uid !== 1) {
            return
          }
          client.value!.setRemoteVideoStreamType(
            user.uid,
            RemoteStreamType.HIGH_STREAM
          )
          const videoTrack = user.videoTrack
          // 自动播放视频
          console.log(user, mediaType, 'host')

          videoTrack?.play('hostVideo', {
            fit: 'contain'
          })
        }
      })
      getDevices()
    })

    return () => {
      return (
        <div class={styles.preview}>
          {/* <div onClick={handleMute}>禁言</div> */}
          {isJoined.value ? (
            <div class={styles.onLine}>
              <Camera className={styles.host} id="hostVideo" />
              <Camera className={styles.mine} id="meVideo">
                <div class={styles.username}>
                  <img src={nameImg} />
                </div>
              </Camera>
            </div>
          ) : (
            <div>
              <div class={styles.title}>{t('room.preview.title')}</div>
              <div class={styles.showBox}>
                <Camera className={styles.testCamera} id="meVideo" />
                <div class={styles.volume}>
                  <div class={styles.micIcon}>
                    <img src={micImg} />
                  </div>
                  <div class={styles.volumeBar}>
                    <div class={styles.volume_outer}>
                      <div
                        class={styles.volume_inner}
                        style={{
                          height: localVolumeLevel.value * 157 + 'px'
                        }}
                      ></div>
                    </div>
                  </div>
                  <div class={styles.maxMic}>{deviceInfo.micName || 'Mic'}</div>
                </div>
              </div>
            </div>
          )}

          <div class={styles.control}>
            <ControlItem
              icon={localAudioTrack.value?.muted ? unmuteImg : muteImg}
              label={
                (localAudioTrack.value?.muted
                  ? t('room.preview.Unmute')
                  : t('room.preview.Mute')) as string
              }
              onClick={switchMute}
            />
            <ControlItem
              icon={localVideoTrack.value?.muted ? noCamImg : camImg}
              label={
                (localVideoTrack.value?.muted
                  ? t('room.preview.OpenCam')
                  : t('room.preview.StopCam')) as string
              }
              onClick={switchCamera}
            />
            <ControlItem
              icon={settingsImg}
              label={t('room.preview.Settings') as string}
              onClick={openSetting}
            />
            {isJoined.value && (
              <ControlItem
                icon={leaveImg}
                label={t('room.preview.Leave') as string}
                onClick={leaveRoom}
              />
            )}
          </div>
          {!isJoined.value && (
            <div class={styles.form}>
              <div class={styles.nameInput}>
                <p class={styles.nameInputLabel}>{t('room.preview.guestLabel')}</p>
                <el-input
                  v-model={name.value}
                  class={styles.nameInputValue}
                ></el-input>
              </div>
              <div
                class={styles.action}
                v-loading={joinLoading.value}
                onClick={joinRoom}
              >
                {t('room.preview.join')}
              </div>
            </div>
          )}

          <SettingDialog
            deviceList={deviceList}
            visible={showSetting.value}
            value={deviceInfo}
            onConfirm={handleConfirm}
            onClose={() => (showSetting.value = false)}
          />
        </div>
      )
    }
  }
})
