import { defineComponent } from '@vue/composition-api'
import styles from '../../index.module.scss'

const noCameraImg = require('@/assets/images/room/no-camera.png')

export default defineComponent({
  props: {
    id: String,
    className: String
  },
  setup(props, { slots }) {
    return () => (
      <div class={[styles.camera, props.className]} id={props.id}>
        <img class={styles.noCamera} src={noCameraImg} />
        {slots.default?.()}
      </div>
    )
  }
})
