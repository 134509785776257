import { defineComponent } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'

import styles from '../../index.module.scss'

const cameraImg = require('@/assets/images/room/camera.png')

const Auth = defineComponent({
  props: ['onAllow'],
  setup(_props, { emit }) {
    const { t } = useI18n()
    const allowHandle = () => {
      emit('allow', undefined)
    }
    return () => (
      <div class={styles.auth}>
        <div class={styles.title}>{t('room.auth.title')}</div>
        <div class={styles.camera}>
          <img src={cameraImg} />
          <div class={styles.access}>{t('room.auth.access')}</div>
        </div>
        <div class={styles.button} onClick={allowHandle}>
          {t('room.auth.button')}
        </div>
      </div>
    )
  }
})

export default Auth
