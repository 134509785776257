import i18n from '@/locales'
import { defineComponent, ref } from '@vue/composition-api'
import styles from '../index.module.scss'

const logoImg = require('@/assets/images/room/logo.png')
const languageImg = require('@/assets/images/room/language.png')
const upArrowImg = require('@/assets/images/room/up-arrow.png')

export default defineComponent({
  setup() {
    const list = [
      {
        label: 'English',
        value: 'en'
      },
      {
        label: '简体中文',
        value: 'zh-Hans'
      },
      {
        label: '繁体中文',
        value: 'zh-Hant'
      }
    ]
    const language = ref(localStorage.getItem('language') || list[0].value)

    const handleClick = (val) => {
      language.value = val
      i18n.locale = val
      localStorage.setItem('language', val)
    }

    return {
      language,
      handleClick,
      list
    }
  },
  render() {
    const { language, handleClick, list } = this

    const name = list.find((item) => item.value === language)?.label

    return (
      <div class={styles.top}>
        <div class={[styles.maxWidth, styles.header]}>
          <div class={styles.logo}>
            <img src={logoImg} />
          </div>
          <div class={styles.action}>
            <el-dropdown onCommand={handleClick}>
              <span class={styles.changeLanguage}>
                <img src={languageImg} />
                <span class={styles.language}>{name}</span>
                <img src={upArrowImg} />
              </span>
              <el-dropdown-menu slot="dropdown">
                {list.map((item) => {
                  return (
                    <el-dropdown-item command={item.value}>
                      {item.label}
                    </el-dropdown-item>
                  )
                })}
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    )
  }
})
